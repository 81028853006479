/*
 * Contact form
 */

$(document).ready(function() {
    function scrollTo(element, speed, callback) {
        $('html, body').animate({
            scrollTop: element.offset().top - 150
        }, speed, callback);
    }

    if ($('.contact-form-message').length) {
        scrollTo($('.contact-form-message'), 1000);
    }
});
